// 判断接口
import axios from "axios";
import { BAS, HAR } from "./ip-config";
import { message } from "antd";
/**
 * 数组移除某个元素ss
 * @param {*} object
 * @param {*} key
 * @param {*} value
 * @eq List.remove('id', 1)
 */
Array.prototype.remove = function (key, value) {
  var index = this.findIndex((item) => item[key] === value);
  if (index > -1) {
    this.splice(index, 1);
  }
};

// 瓶号转通道号和瓶号号
export const numberToChanneAndNumber = (num) => {
  if (!num) {
    return "";
  }
  return `${parseInt((num - 1) / 6) + 1}号通道 ${num}`;
};

// 清楚localstorage的方法
export const clearLocalStorage = () => {
  let localStorageLength = window.localStorage.length;
  for (var i = 0; i < localStorageLength; i++) {
    let key = window.localStorage.key(i);
    // console.log("🚀 ~ clearLocalStorage ~ key:", key, window.localStorage)
    // 系统语音设置不可清除
    if (key !== "language") {
      window.localStorage.removeItem(window.localStorage.key(i));
    }
  }
  // sessionStorage.clear()
};

/**
 * 数组替换某个元素
 * @param {*} object
 * @param {*} key
 * @param {*} value
 * @eq List.replace(item,'id', 1)
 */

Array.prototype.replace = function (object, key, value) {
  var index = this.findIndex((item) => item[key] === value);
  if (index > -1) {
    this.splice(index, 1, object);
  }
};

// JSON.parese后的顺序保持不变
export const JsonParseNoSort = function (jsonString) {
  const mapObject = JSON.parse(jsonString, (key, value) => {
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return new Map(Object.entries(value));
    }
    return value;
  });
  const jsonObject = Object.fromEntries(mapObject);

  // const jsonString1 = JSON.stringify(jsonObject1);
  // console.log(jsonString1);
  return jsonObject;
};

export const ApiRequest = (item) => {
  switch (item) {
    case "BAS":
      axios.defaults.baseURL = BAS;
      break;
    case "HAR":
      axios.defaults.baseURL = HAR;
      break;
    default:
      axios.defaults.baseURL = BAS;
      break;
  }
};

// 跳转页面
export function goto(url) {
  window.location.hash = url;
}

/**debounce
 * @desc 函数防抖---“立即执行版本” 和 “非立即执行版本” 的组合版本
 * @param func 需要执行的函数
 * @param wait 延迟执行时间（毫秒）
 * @param immediate---true 表立即执行，false 表非立即执行
 **/
export function debounce(func, wait, immediate) {
  let timer, result;

  return function () {
    let context = this;
    let args = arguments;

    if (timer) clearTimeout(timer);

    if (immediate) {
      // 如果是在immediate模式下，且尚未设置过timer，则立即调用函数
      if (!timer) {
        result = func.apply(context, args);
      }
      timer = setTimeout(() => {
        timer = null; // 重置timer
        if (!immediate) {
          result = func.apply(context, args);
        }
      }, wait);
      return result;
    } else {
      timer = setTimeout(function () {
        result = func.apply(context, args);
      }, wait);
      return result;
    }
  };
}
// 获取地址栏query参数： getQueryParams('uuid')
export function getQueryParams(paraName) {
  var url = decodeURIComponent(document.location.toString());
  var arrObj = url.split("?");
  if (arrObj.length > 1) {
    var arrPara = arrObj[1].split("&");
    var arr;
    for (var i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
}
/**
 * 查找列表中是否有某个元素
 * @param {*} list 目标数组
 * @param {*} attr 目标属性['attr1','attr2'] || 'attr'
 * @param {*} type 属性值['type1',type2] || 'type'
 * @returns
 */
export const listFindItem = (list, attr, type) => {
  if (!list) return "";
  return list.find((item) => {
    let result = false;
    if (typeof attr == "string") {
      result = item[attr] == type;
    } else {
      result = item[attr[0]] == type[0] && item[attr[1]] == type[1];
    }

    return result;
  });
};
// 解析search传参
export function getSearch(str) {
  if (str.indexOf("?") != -1) {
    let obj = {};
    str
      .split("?")[1]
      .split("&")
      .map((el) => {
        let arr = el.split("=");
        obj[arr[0]] = arr[1];
      });
    return obj;
  } else {
    return;
  }
}

// 转换日期格式
export function renderTime(date) {
  var dateee = new Date(date).toJSON();
  return new Date(+new Date(dateee) + 8 * 3600 * 1000)
    .toISOString()
    .replace(/T/g, " ")
    .replace(/\.[\d]{3}Z/, "");
}

export const searchRoute = (path, routes) => {
  let result = {};
  for (let item of routes) {
    //递归条件

    if (item.path === path) return item;
    if (item.children) {
      const res = searchRoute(path, item.children);
      if (Object.keys(res).length) result = res;
    }
  }

  return result;
};

// 判断的是否是JSON字符串
export const isType = (str) => {
  if (typeof str == "string") {
    try {
      var obj = JSON.parse(str);
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj == "object" && obj) {
        return true;
      } else {
        //不是就返回false
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
};

// 新开窗口
export const openNewWindow = (url) => {
  const canOpenWindow = JSON.parse(localStorage.getItem("hasWindow") || false);

  // if (!canOpenWindow) {
  // localStorage.setItem("hasWindow", true);
  // 桌面打包后，/#/ 作为系统根目录，无法识别，需完整路径
  const MainUrl = window.location.pathname + url;
  window.open(MainUrl);
  // } else {
  //     message.warning('编辑和合成窗口，只能同时打开一个！')
  // }
};

/**
 * 对象数组去重
 * @param {Array} arr 需要去重的对象数组
 * @returns {Array} 去重后的对象数组
 */
export const ArrayUnique = (arr) => {
  // 创建一个Set集合,用于存储已遍历过的对象
  const set = new Set();
  // 过滤出不重复的对象
  const uniqueArr = arr.filter((obj) => {
    // 将对象转换为字符串,作为Set集合的key
    const key = JSON.stringify(obj);
    // 如果Set集合中不存在该key,则添加并返回true(保留该对象)
    return set.has(key) ? false : set.add(key);
  });
  return uniqueArr;
};

/**
 * 将数字四舍五入到小数点后三位
 *
 * @param number 要处理的数字
 * @returns 返回四舍五入后的数字
 */
export function roundToThreeDecimals(number) {
  // 判断number是否为数字
  if (typeof number !== "number") {
    return number;
  }
  return Math.round(number * 1000) / 1000;
}

/**
 * 根据moduleName和number在对象数组中添加或删除对象
 * @param {Array} arr 原始对象数组
 * @param {string} blockType 对象的moduleName属性
 * @param {number} num 对象的number属性
 * @returns {Array} 操作后的对象数组
 */
export const UpdateArray = (arr, obj) => {
  // 查找是否存在需要删除的对象
  const itemToRemove = arr.find(
    (item) => item.materialNo === obj?.materialNo && item.num === obj?.num
  );

  // 如果存在,则使用filter方法删除该对象
  if (itemToRemove) {
    const newArr = arr.filter(
      (item) => !(item.materialNo === obj?.materialNo && item.num === obj?.num)
    );
    return newArr;
  } else {
    // 如果不存在,则添加该对象到数组中
    const newArr = [...arr, obj];
    // console.log("🚀 ~ UpdateArray ~ arr:", newItem, arr, newArr)
    return newArr;
  }
};

/**
 * 根据模块名和序号对数组进行排序
 *
 * @param arr 要排序的数组
 * @returns 返回排序后的数组
 */
export function sortByModuleNameAndNumber(arr) {
  // 使用稳定排序算法进行排序
  return arr.sort((a, b) => {
    // 首先比较moduleName
    if (a?.target?.moduleName && b?.target?.moduleName) {
      const moduleNameCompare = a.target.moduleName.localeCompare(
        b.target.moduleName
      );
      if (moduleNameCompare !== 0) {
        return moduleNameCompare;
      }
      // 如果moduleName相同，则比较number
      return a.target.number - b.target.number;
    }
  });
}
//生成UUID
export const generateUUID = (len, radix) => {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let UUID = [],
    i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) UUID[i] = chars[0 | (Math.random() * radix)];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    UUID[8] = UUID[13] = UUID[18] = UUID[23] = "-";
    UUID[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!UUID[i]) {
        r = 0 | (Math.random() * 16);
        UUID[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return UUID.join("");
};

/**
 * 判断当前环境是否为移动端
 *
 * @returns 返回布尔值，表示当前环境是否为移动端
 */
export function isMobile() {
  const screenWidth = window.innerWidth;
  const maxMobileWidth = 768; // 根据需求调整这个阈值

  if (screenWidth <= maxMobileWidth) {
    return true; // 认为是手机或小屏幕设备
  } else {
    return false; // 认为是平板或桌面设备
  }
}

/**
 * 判断传入的值是否为字符串类型
 * @param {*} value - 需要判断的值
 * @returns {boolean} - 如果是字符串返回true，否则返回false
 */
export function isString(value) {
  return Object.prototype.toString.call(value) === "[object String]";
}
